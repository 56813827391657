//React
import { useState, useEffect } from 'react';

//System
import { register } from '../../system/stateManager';

//System Helpers
import { performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//System
import { getState } from '../../system/stateManager';

//Styles
import './styles.css';

//Events
const onClickAction = () => {
	(async () => {
		const { action, query } = getState('reasonAction');

		const reason = document.getElementById('reason').value;

		const res = await iwdGet(action, {
			reason,
			...query
		});

		if (res.success)
			performBack();
	})();
};

//Components
const ReasonAction = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'reasonAction', setter, state), []);

	if (!state.vis)
		return;

	return (
		<div className='cpnChangePassword centered'>
			<div className='title'>
				{state.actionName}
			</div>
			<div className='bottom'>
				<input id='reason' placeholder='reason...' />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>Back</div>
				<div className='btn right' onClick={onClickAction}>
					{state.actionName}
				</div>
			</div>
		</div>
	);
};

export default ReasonAction;
