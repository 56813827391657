/* eslint-disable max-lines, max-lines-per-function */

//React
import { useState, useEffect } from 'react';

//System
import { register, setState, getState } from '../../system/stateManager';

//System Helpers
import { setup as setupBackButton, performBack } from '../../helpers/backButton';
import iwdGet from '../../helpers/iwdGet';

//Events
import onGetInfo from './events/onGetInfo';

//Styles
import './styles.css';

//Events
const onClickBan = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'reasonAction');

	setState('userInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Ban',
		action: 'mtBan',
		query: { username }
	});
};

const onClickUnban = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'reasonAction');

	setState('userInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Unban',
		action: 'mtUnban',
		query: { username }
	});
};

const onClickWhitelist = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'reasonAction');

	setState('userInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Whitelist',
		action: 'mtWhitelist',
		query: { username }
	});
};

const onClickUnwhitelist = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'reasonAction');

	setState('userInfo', {
		vis: false,
		data: null
	});

	setState('reasonAction', {
		vis: true,
		actionName: 'Un-Whitelist',
		action: 'mtUnwhitelist',
		query: { username }
	});
};

const onClickCharacter = name => {
	(async () => {
		const response = await iwdGet('mtLookupCharacter', { name });
		if (response.success === false)
			return;

		setupBackButton('userInfo', 'characterInfo');

		setState('userInfo', { vis: false });

		setState('characterInfo', {
			vis: true,
			selected: response
		});
	})();
};

const onClickUser = username => {
	(async () => {
		const response = await iwdGet('mtLookupUser', { username });
		if (response.success === false)
			return;

		setState('userInfo', { selected: response });
	})();
};

const onClickModLog = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'modLog');

	setState('userInfo', { vis: false });

	setState('modLog', {
		vis: true,
		selected: {
			username,
			name: null
		},
		needsRefresh: true
	});
};

const onClickChatLog = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'chatLog');

	setState('userInfo', { vis: false });

	setState('chatLog', {
		vis: true,
		selected: {
			username,
			name: null
		},
		needsRefresh: true
	});
};

const onClickPatreonLog = async () => {
	const { selected: { username } } = getState('userInfo');

	setupBackButton('userInfo', 'patreonLog');

	setState('userInfo', { vis: false });

	setState('patreonLog', {
		vis: true,
		selected: { username },
		needsRefresh: true
	});
};

const onClickStash = async () => {
	const { data: { stash } } = getState('userInfo');

	setupBackButton('userInfo', 'inventory');

	setState('userInfo', { vis: false });

	setState('inventory', {
		vis: true,
		title: 'Stash',
		items: stash
	});
};

//Components
const UsernamesWithIp = () => {
	const { usernamesWithIp, data } = getState('userInfo');
	if (!usernamesWithIp || !data)
		return null;

	const list = usernamesWithIp.map((l, i) => {
		const key = `entry-${i}`;

		return (
			<div
				key={key}
				className='entry'
				onClick={onClickUser.bind(null, l)}
			>
				{l}
			</div>
		);
	});

	return (
		<div className='section'>
			<div className='section-title'>
				Usernames with same IP
			</div>
			<div className='list'>
				{list}
			</div>
		</div>
	);
};

const Characters = () => {
	const { characters, data } = getState('userInfo');
	if (!characters || !data)
		return null;

	const list = characters.map((l, i) => {
		const key = `entry-${i}`;

		return (
			<div
				key={key}
				className='entry'
				onClick={onClickCharacter.bind(null, l)}
			>
				{l}
			</div>
		);
	});

	return (
		<div className='section'>
			<div className='section-title'>
				Characters with same Username
			</div>
			<div className='list'>
				{list}
			</div>
		</div>
	);
};

const CharactersWithIp = () => {
	const { charactersWithIp, data } = getState('userInfo');
	if (!charactersWithIp || !data)
		return null;

	const list = charactersWithIp.map((l, i) => {
		const key = `entry-${i}`;

		return (
			<div
				key={key}
				className='entry'
				onClick={onClickCharacter.bind(null, l)}
			>
				{l}
			</div>
		);
	});

	return (
		<div className='section'>
			<div className='section-title'>
				Characters with same IP
			</div>
			<div className='list'>
				{list}
			</div>
		</div>
	);
};

const UserInfo = () => {
	const [state, setter] = useState({ vis: false });

	useEffect(register.bind(null, 'userInfo', setter, state), []);
	useEffect(onGetInfo, [state.selected?.username, state.vis]);

	if (!state.vis)
		return;

	const btnBanClasses = `btn right${state.data?.isBanned ? ' disabled' : ''}`;
	const btnUnbanClasses = `btn${!state.data?.isBanned ? ' disabled' : ''}`;

	const btnWhitelistClasses = `btn${state.data?.isWhitelisted ? ' disabled' : ''}`;
	const btnUnwhitelistClasses = `btn${!state.data?.isWhitelisted ? ' disabled' : ''}`;

	return (
		<div className='cpnUserInfo centered'>
			<div className='title'>
				User Info:
				{' '}
				{state.selected?.username}
			</div>
			<div className='bottom'>
				<UsernamesWithIp />
				<CharactersWithIp />
				<Characters />
			</div>
			<div className='toolbar'>
				<div className='btn gray' onClick={performBack}>Back</div>
				<div className={btnBanClasses} onClick={onClickBan}>Ban</div>
				<div className={btnUnbanClasses} onClick={onClickUnban}>Unban</div>
				<div className={btnWhitelistClasses} onClick={onClickWhitelist}>Whitelist</div>
				<div className={btnUnwhitelistClasses} onClick={onClickUnwhitelist}>Un-Whitelist</div>
				<div className='btn' onClick={onClickPatreonLog}>Patreon Log</div>
				<div className='btn' onClick={onClickStash}>Stash</div>
				<div className='btn' onClick={onClickModLog}>Mod Log</div>
				<div className='btn disabled' onClick={onClickChatLog}>Chat Log</div>
			</div>
		</div>
	);
};

export default UserInfo;
